import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/imageAndText.css";
import { Link } from "react-router-dom";
const ReactMarkdown = require("react-markdown/with-html");

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="imageAndTextContainer"
        style={{
          backgroundColor: this.props.bgColor,
          position: "relative"
        }}
      >
        <CockpitSettingsWrapper data={this.props.data}>
          <div className="imageAndText">
            <div
              className="imageAndTextElem thegrid "
              style={
                this.props.data.settings.reverse
                  ? { flexDirection: "row-reverse" }
                  : { flexDirection: "row" }
              }
            >
              <div
                className="x50 s-x100 xs-x100 "
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <ConditionalWrapper
                  condition={
                    this.props.data.settings.image_url &&
                    this.props.data.settings.image_url.indexOf("/") === 0
                  }
                  wrapper={children => (
                    <Link
                      to={
                        !this.props.locale
                          ? this.props.data.settings.image_url
                          : `/${this.props.locale}${
                              this.props.data.settings.image_url
                            }`
                      }
                      title={this.props.data.settings.image.title}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <ConditionalWrapper
                    condition={
                      this.props.data.settings.image_url &&
                      this.props.data.settings.image_url.indexOf("/") !== 0
                    }
                    wrapper={children => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.props.data.settings.image_url}
                        title={this.props.data.settings.image.title}
                      >
                        {children}
                      </a>
                    )}
                  >
                    <Fade bottom>
                      <img
                        src={`${process.env.REACT_APP_STORAGE}${
                          this.props.data.settings.image.path
                        }`}
                        alt={this.props.data.settings.image.title}
                        style={{
                          marginTop: this.props.data.settings.image_offset
                            ? "-4rem"
                            : 0
                        }}
                      />
                    </Fade>
                  </ConditionalWrapper>
                </ConditionalWrapper>
              </div>

              <div
                className=" x50 s-x100 xs-x100 padding text"
                style={
                  this.props.data.settings.reverse
                    ? {
                        padding: "1rem",
                        display: "flex",

                        alignItems: "center",
                        color: this.props.textColor,
                        zIndex: 10
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",

                        padding: "1rem",
                        alignItems: "center",
                        color: this.props.textColor,
                        zIndex: 10
                      }
                }
              >
                <div
                  style={{
                    color: this.props.data.settings.text_color
                      ? this.props.data.settings.text_color
                      : this.props.textColor
                  }}
                >
                  <ReactMarkdown
                    source={this.props.data.settings.text}
                    escapeHtml={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </CockpitSettingsWrapper>
      </div>
    );
  }
}

export default Content;
