import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CockpitSettingsWrapper from "./CockpitSettingsWrapper";
import "./styles/articlesFeed.css";

class ArticlesFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": process.env.REACT_APP_TOKEN
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.locale,
          sort: { date: -1 },
          limit: 1
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  render() {
    return (
      <div className="articlesFeed">
        <div
          className="articlesFeedContainer"
          style={{
            backgroundColor: this.props.bgColor,
            color: this.props.textColor,
            position: "relative"
          }}
        >
          <CockpitSettingsWrapper data={this.props.data}>
            <Fade bottom cascade>
              <div className="">
                <div className="articlesFeedContainerInner">
                  {!this.state.fetching
                    ? this.state.data.entries.map((article, i) => {
                        const splittedDate = article.date.split("-");

                        return (
                          <div>
                            <div>
                              <div
                                className="articleDate"
                                style={{ color: "#ccc" }}
                              >
                                <br />
                                {splittedDate[2]}-{splittedDate[1]}-
                                {splittedDate[0]}
                                <br />
                              </div>
                              <h2 className="articleTitle">{article.title}</h2>
                              <div className="articleExcerpt">
                                <br />
                                {article.excerpt}
                                <br />
                                <br />
                                <Button
                                  variant="outlined"
                                  component={Link}
                                  className="articleFeedCard"
                                  key={i}
                                  title={article.title}
                                  to={
                                    !this.props.locale
                                      ? `/projects/${article.url_handle}`
                                      : `/${this.props.locale}/projects/${
                                          article.url_handle
                                        }`
                                  }
                                >
                                  Read More
                                </Button>
                              </div>
                            </div>
                            <div>
                              <img
                                src={`${
                                  process.env.REACT_APP_API_ENDPOINT
                                }cockpit/image?token=${
                                  process.env.REACT_APP_TOKEN
                                }&src=${
                                  article.image._id
                                }&w=1024&h=1024&q=50&o=true`}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div>
                  {this.props.data.settings && this.props.data.settings.cta ? (
                    <div style={{ textAlign: "left", padding: "2rem" }}>
                      <Button
                        className="cta"
                        style={{
                          backgroundColor: this.props.textColor,
                          color: this.props.bgColor
                        }}
                        variant="contained"
                        component={Link}
                        to={
                          !this.props.locale
                            ? `/projects`
                            : `/${this.props.locale}/projects`
                        }
                      >
                        {this.props.data.settings.cta}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </Fade>
          </CockpitSettingsWrapper>
        </div>
      </div>
    );
  }
}

export default ArticlesFeed;
