import React from "react";
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
const CockpitSettingsWrapper = props => {
  return (
    <ConditionalWrapper
      condition={props.data.settings.bg_image}
      wrapper={children => (
        <div
          className="bgWrapper"
          style={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${process.env.REACT_APP_STORAGE}${
              props.data.settings.bg_image.path
            })`,
            backgroundSize: "cover",
            backgroundPosition: props.data.settings.bg_alignment
              ? props.data.settings.bg_alignment
              : "center center",
            backgroundAttachment: props.data.settings.bg_fixed
              ? "fixed"
              : "scroll"
          }}
        >
          {children}
        </div>
      )}
    >
      <ConditionalWrapper
        condition={props.data.settings.overlay_color}
        wrapper={children => (
          <div
            style={{
              height: "100%",
              width: "100%",
              background: props.data.settings.overlay_color,
              background: props.data.settings.overlay_color2
                ? `linear-gradient(90deg, ${
                    props.data.settings.overlay_color
                  } 0%, ${props.data.settings.overlay_color2} 100%)`
                : props.data.settings.overlay_color
            }}
          >
            {children}
          </div>
        )}
      >
        <ConditionalWrapper
          condition={props.data.settings.min_height}
          wrapper={children => (
            <div
              style={{
                minHeight: props.data.settings.min_height,
                flexWrap: "wrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {children}
            </div>
          )}
        >
          {props.children}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};

export default CockpitSettingsWrapper;
